import path from 'path'
import Vue from 'vue'

export function registerComponents() {
  const requireThemeComponents = require.context(
    // The relative path of the components folder
    '../themes',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /\.(vue)$/,
  )

  // Register local components globally
  return requireThemeComponents.keys().map((filePath) => {
    const fileName = path.basename(filePath, '.vue')
    const theme = path.dirname(filePath).split(path.sep)[1]
    const name = `${theme}-${fileName}`
    Vue.component(name, requireThemeComponents(filePath).default)
    return name
  })
}

export function getAssetURL({ theme = 'default', asset } = {}) {
  try {
    // eslint-disable-next-line
    return require(`../themes/${theme}/assets/${asset}`)
  } catch (e) {
    // eslint-disable-next-line
    return require(`../themes/default/assets/${asset}`)
  }
}
