<template>
  <div v-if="config.auth_backends && config.auth_backends.length">
    <v-component :is="externalLoginText" v-if="componentExists(externalLoginText)" />
    <v-card-text v-else>
      <div v-if="config.login_form_enabled" class="text-center">{{ $t('orLoginWith') }}</div>
      <div v-else class="text-center">{{ $t('loginWith') }}</div>
    </v-card-text>
    <v-card-actions v-for="backend in config.auth_backends" :key="backend.id">
      <v-btn block depressed :href="addNextParameter(backend.url)" color="primary"
        ><v-icon left>{{ authProviderIcon(backend.id) }}</v-icon
        >{{ $t(backend.label) }}</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import { branding } from '@/components/mixins'
const AUTH_PROVIDER_ICONS = {
  'apple-id': 'fab fa-apple',
  'google-openidconnect': 'fab fa-google',
  'microsoft-graph': 'fab fa-microsoft',
  'azuread-v2-tenant-oauth2': 'fab fa-microsoft',
  oidc: 'fab fa-openid',
}
export default {
  name: 'ExternalLogin',
  mixins: [branding],
  props: {
    next: {
      type: String,
      default: '',
    },
  },
  methods: {
    authProviderIcon(provider) {
      return AUTH_PROVIDER_ICONS[provider] || 'fas fa-sign-in-alt'
    },
    addNextParameter(url) {
      if (this.$route.query.next) {
        return `${url}?next=${encodeURIComponent(this.$route.query.next)}`
      } else if (this.next) {
        return `${url}?next=${encodeURIComponent(this.next)}`
      } else {
        return url
      }
    },
  },
}
</script>
